import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"


const GuitarPickups = () => {
  return (
    <PageLayout>
      <SEO
        title="Guitar Pickups | Your Custom Build | King Electric Guitars"
        ogDescription="Guitar pickups are arguably the biggest contributor to the overall sound of your guitar. I can guide you to the exact tone you're after. Let's build your dream guitar!"
      />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h1>Guitar Pickups</h1>
        <p>
          Guitar pickups are arguably the biggest contributor to the overall sound of your guitar. There are many large companies like DiMarzio, EMG, Gibson and Seymour Duncan who make popular and affordable options. But these days there are also dozens of specialty and boutique pickup manufacturers offering practically anything you can imagine.
        </p>
        <p>
          But, it's important not to get caught up in marketing when it comes to manufacturers. What matters is design. Each pickup type has a specific design purpose that will influence your guitar's sound and your playing.
        </p>
        <FormWrapper
          modal
          buttonProps={{
            content: "Reach Out. Let's Talk!",
            size: "tiny",
            icon: "chat",
          }}
        >
          <RequestMoreInfo />
        </FormWrapper>
      </div>
    }
    textRight={
      <div>
        <img src="/images/guitar-pickups.jpg" alt="Guitar Pickups" />
      </div>
    }
  />
  <div className="section textured">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/guitar-pickups-humbucker-single-coil.jpg" alt="Guitar Pickups Single Coil vs Humbucker" />
        </div>
      }
      textRight={
        <div>
          <h2>Single Coil vs Humbucker</h2>
          <p>
            Single coil pickups consist of magnets wrapped in a single coil of wire. They were developed in the 1930's at the dawn of the electric guitar by Harry De Armond. Single coil pickups create a clear signal and are sensitive to articulation from the player. But, they also can act as an antenna, picking up electrical interference. To combat this, the humbucker was developed. It's actually two single-coil pickups wired out of phase with each other. This "bucks" the hum created by single coils. But, this design changes the tone of the pickup. Humbuckers will be darker in tone and often will have hotter output as compared to single coils.
          </p>
        </div>
      }
    />
  </div>
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h2>Pickup Construction</h2>
        <p>
          Pickups are fairly simple electrical components. They create a magnetic field which picks up vibrations from the strings and turns it into a low level electrical signal. They consist of magnets, wire, and various forms of insulating material. Because they create very low electrical signals, which ends up being heavily amplified, small changes to a design can have a big impact on the magnetic field and sound each pickup produces.
        </p>
      </div>
    }
    textRight={
      <div>
        <img src="/images/guitar-pickups-construction.jpg" alt="Guitar Pickups Construction" />
      </div>
    }
  />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <img src="/images/guitar-pickups-alnico5.jpg" alt="Guitar Pickup Magnets" />
      </div>
    }
    textRight={
      <div>
        <h3>Alnico Magnets, Ceramic Magnets, and CuNiFe Magnets</h3>
        <p>
          Types of Alnico Magnets for Guitar Pickups. Alnico 2, 3, 4, 5 and on up. Many manufacturers will claim certain tone characteristics for different Alnico types. And then there are Ceramic magnets often found in high output or less expensive pickups. They typically sound brighter, but that may be a tone characteristics you like depending on the guitar. It just depends on what you are after. Some pickups also use CuNiFe magnets, which are more like Alnico, but also brighter in tone.
        </p>
        <p>
          The way the magnets are used in the design makes a big difference on how they sound. Many will have 6 magnet poles, the height of which will be different for each design. Other pickups use bars instead of poles.
        </p>
      </div>
    }
  />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h3>Wire Selection</h3>
        <p>
          Believe it or not, the construction of the wire in the pickup itself will also have an impact on the sound. Most differ in how they are coated. There is Plain Enamel (PE), Polyurethane (P),  Polyurethane Nylon (PN), and Formvar (F). The gauge of the wire used is typically between 41 to 44 AWG, and that too can impact the tone characteristics. You will often hear that a big contributing factor is the number of winds. And, the overall height of the pickup enclosure will also have an effect.
        </p>
        <p>
          There are so many variables, you can't tell by looking at the specification what a pickup will sound like. So, you just have to try things out. Listen to sound samples on YouTube. And if you aren't sure, then let me suggest some options.
        </p>
        <FormWrapper
          modal
          buttonProps={{
            content: "Reach Out. Let's Talk!",
            size: "tiny",
            icon: "chat",
          }}
        >
          <RequestMoreInfo />
        </FormWrapper>
      </div>
    }
    textRight={
      <div>
        <img src="/images/guitar-pickups-wire.jpg" alt="Guitar Pickups Wire" />
      </div>
    }
  />
  <PageWrapper color="grey">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Single Coil Pickups</h2>

          <h3>P-90 Pickups</h3>
          <p>
            Gibson invented the P-90 pickup in the 1930's and it has endured as a favorite of guitarists today. They come in two mounting styles: "dogear" and "soapbar". I personally use the soapbar style because they have the benefit of adjustments screws for changing pickup height. As for the sound, many players consider them to have the attack of other single coils, but with a bit more aggression.
          </p>
        </div>
      }
      textRight={
        <div>                
          <img src="/images/guitar-pickups-p90.jpg" alt="Guitar Pickups P-90" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/guitar-pickups-telecaster.jpg" alt="Guitar Pickups Telecaster" />
        </div>
      }
      textRight={
        <div>
          <h3>Telecaster Pickups</h3>
          <h4>Tele Bridge</h4>
          <p>
            The earliest Telecaster bridge pickups evolved from a Fender lap steel guitar pickup. It has an unusual base plate and is mounted at an angle and typically goes through the Telecaster bridge plate.
          </p>
          <h4>Tele Neck</h4>
          <p>
            Many people assume a Telecaster neck pickup to be a "lipstick" pickup. It isn't. I'll explain more on that later. The Telecaster neck pickup is a 6-pole pickup with a metal cover over it. Because it is a narrower size than most single coils, it uses lower gauge wire to fit the winds in.
          </p>
          <p>
            One characteristic of the traditional Tele pickup setup is the middle position. It can have a "quack" sound that you'll find all over James Brown and similar funk records of the 60's and 70's.
          </p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h3>Strat Pickups</h3>
          <p>
            For a Stratocaster style setup, usually, you will buy a pickup set of three matched single coils. There are dozens of different strat pickup design options from which to choose these days. But generally, they fall into the early vintage period of the late 50's to early 60's, the Hendrix era of mid-to-late 60's, and then the more modern versions Fender makes today. Often the center pickup is reverse wound reverse polarity (<a href="https://www.sweetwater.com/insync/what-are-rwrp-pickups/" target="_blank" rel="noreferrer">RWRP</a>) which allows combining bridge or neck pickups with the center to achieve humbucking. This also results in a scooped-midrange that many players like. But you can also get the center pickup without RWRP. This will provide more midrange when combining the pickups, but it will not "buck" the noise from RF interference. 
          </p>
        </div>
      }
      textRight={
        <div>                
          <img src="/images/guitar-pickups-stratocaster.jpg" alt="Guitar Pickups Stratocaster" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/guitar-pickups-jazzmaster.jpg" alt="Guitar Pickups Jazzmaster" />
        </div>
      }
      textRight={
        <div>
          <h3>Jazzmaster Pickups</h3>
          <p>
            Many people think the Jazzmaster pickup is a P-90. It does look similar, but it is quite a different animal and has its own specific character. They are much wider than a typical P-90, but they are also much thinner. This forces the winds of the wire to cover a bigger area than a typical single coil. They are known for their high frequency chime and low end bloom.
          </p>
        </div>
      }
    />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h3>Lipstick Pickups</h3>
          <p>
            The <a href="https://reverb.com/news/a-guide-to-lipstick-pickups" target="_blank" rel="noreferrer">lipstick pickup</a> was designed by Nathan Daniel of Danelectro. It got its name for being literally housed in a lipstick container that was available in bulk at the time of its release. It uses a single bar magnet with wire wrapped around it. They produce a lower than normal output and have a distinctive clear, jangly sound.
          </p>
          <h3>Gold Foil Pickups</h3>
          <p>
            <a href="https://www.lollarguitars.com/lollar-gold-foil-pickups" target="_blank" rel="noreferrer">Gold Foil pickups</a> were common on the "cheap" Sears guitars and import guitars of the 60's from Japan like Teisco. They have started to gain a following because of their unique tonal characteristics of soft, ultra-clear and bright high-end sound. While not for everyone, they offer a cool, retro vibe for your custom design.
          </p>
          <FormWrapper
            modal
            buttonProps={{
              content: "Reach Out. Let's Talk!",
              size: "tiny",
              icon: "chat",
            }}
          >
            <RequestMoreInfo />
          </FormWrapper>
        </div>
      }
    />
  </PageWrapper>
  <div className="section textured">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Humbucker Pickups</h2>
          <p>
            The humbucker design was invented to combat the noise issue of single coils. PAF and Filtertron pickups were the first to employ these designs.
          </p>
        </div>
      }
      textRight={
        <div>
          <img src="/images/guitar-pickups-humbucker.jpg" alt="Humbucking Guitar Pickups" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/guitar-pickups-paf.jpg" alt="PAF Humbuckers" />
        </div>
      }
      textRight={
        <div>
          <h3>PAF Pickups</h3>
          <p>
            <a href="https://en.wikipedia.org/wiki/Seth_Lover" target="_blank" rel="noreferrer">Seth Lover</a> was the first to get a patent on the humbucking pickup. His design for Gibson has become a staple of guitar tone for decades. It is an evolution of Gibson's original P-90 single coil - essentially using two P-90's with coils that mirror each other - one being reverse wound and reverse polarity. They typically have a high output, which allowed them to easily drive amplifiers of the time into distortion.
          </p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h3>Filter’Tron Pickups</h3>
          <p>
            The <a href="https://reverb.com/news/a-guide-to-filtertron-pickups" target="_blank" rel="noreferrer">Filter’Tron</a> was invented by Ray Butts even before the PAF was invented, but he was slower to get the patent. You see these on Gretsch guitars usually. They are typically lower output than a PAF, but they are set closer to the strings to compensate. They have more high frequencies with a dip in the midrange and a full low end. They can be interesting when paired with single coils, like we did with The Ethermaster. It's a vintage style Telecaster bridge with a Ray Butts Filter’Tron in the neck.
          </p>
        </div>
      }
      textRight={
        <div>                
          <img src="/images/guitar-pickups-filtertron.jpg" alt="Guitar Pickups Filtertron" />
        </div>
      }
    />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h3>Wide Range Pickups</h3>
          <p>
            In the late 60's, Seth Lover left Gibson for Fender, where he designed the <a href="https://lawingmusicalproducts.com/dr-lawings-blog/the-wide-range-humbucker-and-the-genius-of-seth-lover" target="_blank" rel="noreferrer">Wide Range pickup</a>. He wanted to make a humbucker pickup with more pronounced high frequencies like a single coil.  He achieved this by using CuNiFe magnets and a bigger coil than the PAF. 
          </p>
          <FormWrapper
            modal
            buttonProps={{
              content: "Reach Out. Let's Talk!",
              size: "tiny",
              icon: "chat",
            }}
          >
            <RequestMoreInfo />
          </FormWrapper>
        </div>
      }
    />
  </div>
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h2>What Pickups Should You Use?</h2>
        <p>
          My current "stock" pickups are from <a href="https://bootstrappickups.com/" target="_blank" rel="noreferrer">Bootstrap</a>, <a href="https://tonerider.com/" target="_blank" rel="noreferrer">Tonerider</a>, and <a href="https://www.porterpickups.com/gatekeeper/" target="_blank" rel="noreferrer">Gatekeeper</a> (an offshore line of Porter pickups), who offer very affordable, high-quality versions of P-90, PAF, Teles, Strats, and Jazzmaster pickups. But if you are looking for something more specific, I am happy to help you shop for the best options.
        </p>
      </div>
    }
    textRight={
      <div>                
        <img src="/images/guitar-pickups-stock.jpg" alt="Stock Guitar Pickups" />
      </div>
    }
  />
  <SingleColumnBlock
    textPadded
    text={
      <div>
        <h2>Boutique Guitar Pickup Options</h2>
        <p>
          Many smaller boutique pickup makers will offer custom pickups designed to your tone specific needs. These can be custom wound for particular output values, using different types of magnets, and different design techniques. Here are a few you can research:
        </p>
      </div>
    }
  />
  <div className="section tight">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <p>
            <a href="https://tvjones.com/" target="_blank" rel="noreferrer">TV Jones</a> is the go-to manufacturer for Gretch Filtertron style humbuckers.
          </p>
        </div>
      }
      textRight={
        <div>
          <img src="/images/manufacturers/tvjoneslogo.png" alt="TV Jones Pickups" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/manufacturers/curtisnovaklogo.png" alt="Curtis Novak Pickups" />
        </div>
      }
      textRight={
        <div>
          <p>
            <a href="https://www.curtisnovak.com/" target="_blank" rel="noreferrer">Curtis Novak</a> makes incredible Jazzmaster pickups ranging from vintage style to their own modern designs. He also has interesting Gold Foil options.
          </p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <p>
            <a href="https://www.bareknucklepickups.co.uk/" target="_blank" rel="noreferrer">Bare Knuckle</a> are renowned for their PAF pickups based off the '59 era designs.
          </p>
        </div>
      }
      textRight={
        <div>
          <img src="/images/manufacturers/bareknucklelogo.png" alt="Bare Knuckle Pickups" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/manufacturers/lollarlogo.png" alt="Lollar Pickups" />
        </div>
      }
      textRight={
        <div>
          <p>
            <a href="https://www.lollarguitars.com/" target="_blank" rel="noreferrer">Lollar Pickups</a> makes a wide array of pickups including interesting Gold Foil options.
          </p>
        </div>
      }
    />    
    <TextBlock
      textPadded
      textLeft={
        <div>
          <p>
            <a href="https://www.fralinpickups.com/" target="_blank" rel="noreferrer">Fralin Pickups</a> makes exceptional pickups with a focus on matching the specifications of vintage wound pickups.
          </p>
        </div>
      }
      textRight={
        <div>
          <img src="/images/manufacturers/fralinlogo.png" alt="Fralin Pickups" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/manufacturers/porterlogo.png" alt="Porter Pickups" />
        </div>
      }
      textRight={
        <div>
          <p>
            <a href="https://www.porterpickups.com/" target="_blank" rel="noreferrer">Porter Pickups</a> makes a wide array of custom pickup options for Stratocaster style designs including their S90 model which approaches a P-90 sound in a stratocaster housing.
          </p>
        </div>
      }
    />
  </div>
  <SingleColumnBlock
    textPadded
    text={
      <div>
        <h4>Get in touch. Let's talk about building your dream guitar!</h4>
        <RequestMoreInfo />
      </div>
    }
  />
</PageLayout>
  )
}

export default GuitarPickups
